<template>
  <NavBar />

  <div class="detail column container">
    <div class="detail__content position-relative">
      <Search v-model:startTime="startTime" v-model:endTime="endTime" @search="queryHistory" />

      <Locator @click="locationCk" />
      <!-- 地图 -->
      <Map ref="map" />
      <!-- 地图end -->
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Toast } from 'vant'
import { formatTimeStr, decreaseDate, getMilliSecond } from '@/utils/common'
import http from '@/utils/request'

import Search from './components/search.vue'
import Locator from './components/locator.vue'
import Map from './components/map.vue'

export default {
  name: 'CarDetail',
  components: { Search, Locator, Map },
  setup() {
    const store = useStore() //引入vuex
    const route = useRoute()

    const map = ref(null)
    let carInfo = {}

    const { startTime, endTime } = initSearch()

    onMounted(() => {
      const deviceId = store.state.carInfo.deviceId ? store.state.carInfo.deviceId : route.query.deviceId
      initData(deviceId)
    })

    const locationCk = () => {
      setTimeout(() => {
        map.value.getCurrentPos(carInfo)
      }, 100)
    }

    const queryHistory = () => {
      const startStamp = getMilliSecond(startTime.value)
      const endStamp = getMilliSecond(endTime.value)
      if (startStamp > endStamp) {
        Toast('开始时间不能晚于结束时间')
        return
      }
      const diff = endStamp - startStamp
      if (diff > 1000 * 60 * 60 * 24 * 5) {
        Toast('时间区间不允许超过5天')
        return
      }
      const param = {
        deviceId: carInfo.deviceId,
        startTime: startTime.value,
        endTime: endTime.value
      }
      http({
        url: 'track/localtrack.html',
        method: 'post',
        data: param
      }).then(res => {
        if (res.data?.length == 0) {
          Toast(res.message)
          return
        }
        let path = []
        let timeArr = []
        let weightArr = []
        res.data.forEach(el => {
          const { uploadDate, weight } = el
          if (el.x && el.y) {
            path.push(el)
            weightArr.push(weight)
            timeArr.push(uploadDate)
          }
        })
        map.value.drawLine(path)
      })
    }

    const initData = num => {
      http({
        url: 'realtime/getRealTimeData.html',
        method: 'post',
        data: { deviceId: num }
      }).then(carData => {
        if (!carData.success) {
          carData.data = {}
        }
        const { carNumber, companyName, companyId, deviceId, carUploadDate, weight, x, y, updateTime } = carData.data
        const info = {
          carNumber,
          companyName,
          companyId,
          deviceId,
          carUploadDate,
          weight,
          position: [x, y],
          x,
          y,
          updateTime
        }
        store.commit('updateCarInfo', info)
        carInfo = info
        // 子组件先挂载，所以在父组件 mounted 后可以调用实例方法
        map.value.getCurrentPos(carData.data)
      })
    }

    return {
      map,
      startTime,
      endTime,
      queryHistory,
      locationCk
    }
  }
}

//初始化搜索框内容，默认开始时间是当前，结束时间为当前
function initSearch() {
  const startTime = ref('')
  const endTime = ref('')
  const startDate = new Date()
  startDate.setSeconds(0)
  startDate.setMinutes(0)
  startDate.setHours(0)
  const endDate = new Date()
  endDate.setSeconds(59)
  endDate.setMinutes(59)
  endDate.setHours(23)

  startTime.value = formatTimeStr(startDate)
  endTime.value = formatTimeStr(endDate)
  return { startTime, endTime }
}
</script>
<style lang="scss" scoped>
.detail {
  background-color: #fff;
  color: #646566;
  &__content {
    overflow-y: hidden;
    height: 100%;
  }
}
</style>
