<template>
  <div
    ref="locator"
    class="locator flex-center position-absolute"
    @touchstart.stop="startHandler"
    @touchmove.stop="moveHandler"
    @touchend.stop="endHandler"
  >
    <van-image width="16" height="16" :src="locationPic" />
    <span class="none-select">实时定位</span>
  </div>
</template>
<script>
import { ref, watch, onBeforeUnmount } from 'vue'
import locationPic from '@/assets/Orientation/location.png'

export default {
  emits: ['click'],
  setup(props, { emit }) {
    const locator = ref(null)
    let timer = null
    // 获取 locator 位置
    const getLocatorPos = () => getTargetPosition(locator.value)
    // 使用的 rem 布局，挂载后需要计算原始位置
    watch(locator, () => {
      const pos = getLocatorPos()
      const parent = locator.value.offsetParent
      // 保存父元素宽高
      parentSize.width = parent.clientWidth
      parentSize.height = parent.clientHeight

      origin.top = pos.y
      origin.left = pos.x

      position.top = pos.y
      position.left = pos.x

      cursorX = pos.x
      cursorY = pos.y

      loop()
    })

    onBeforeUnmount(() => {
      cancelAnimationFrame(timer)
      timer = null
    })

    // 保存父元素宽高，移动不能超出区域
    const parentSize = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    // 移动的原始位置
    const origin = {
      left: 0,
      top: 0
    }
    // 目标位置
    const position = {
      left: 0,
      top: 0
    }
    // 偏移量，touch 事件触发位置和元素 offset 值不相等，需要保留偏移量计算位置
    const offset = {
      x: 0,
      y: 0
    }
    // 记录 touchstart 位置信息
    let startTime = 0

    // 每次计算后 x，y 位置
    let cursorX = 0,
      cursorY = 0
    const followSpeed = 0.3 // 控制动画速度

    // 触摸开始处理，保存初始位置等信息
    const startHandler = ({ touches, timeStamp }) => {
      const e = touches[0] // 获取 touch 事件实例
      const { clientX, clientY } = e
      // 保存触发时间
      startTime = timeStamp
      const pos = getLocatorPos()
      // 保存位置信息
      origin.top = pos.y
      origin.left = pos.x

      // 计算偏移量
      offset.x = clientX - pos.x
      offset.y = clientY - pos.y
    }

    // 移动过程同样需要更新位置
    const moveHandler = ({ touches }) => {
      const e = touches[0]
      updatePos(e)
    }

    // 移动结束的处理，如果移动距离，或者 touch 事件很短，认为是点击事件
    const endHandler = ({ changedTouches, timeStamp }) => {
      const e = changedTouches[0] // 获取 touch 事件示实例，end 时候已经移除，所以要在 changedTouches 获取
      updatePos(e)
      const endTime = timeStamp
      const duration = endTime - startTime
      // 移动的距离
      const moveY = locator.value.offsetTop - origin.top
      const moveX = locator.value.offsetLeft - origin.left
      // 触发事件过短，或者位移太小认为是点击事件
      if (duration < 400 || (Math.abs(moveY) < 2 && Math.abs(moveX) < 2)) {
        emit('click')
      }
    }

    // requestAnimationFrame 自动更新计算
    function loop() {
      // 更新位置
      cursorX = lerp(cursorX, position.left, followSpeed)
      cursorY = lerp(cursorY, position.top, followSpeed)

      locator.value.style.top = cursorY + 'px'
      locator.value.style.left = cursorX + 'px'

      timer = requestAnimationFrame(loop)
    }

    // 更新位置信息
    function updatePos(e) {
      if (!e) return
      const { clientX, clientY } = e
      const newX = clientX - offset.x
      const newY = clientY - offset.y
      position.left = newX > 0 ? newX : 0
      position.top = newY > 0 ? newY : 0
    }

    // 返回目标元素相对父级位置信息
    function getTargetPosition(target) {
      if (!target) return
      return {
        x: target.offsetLeft,
        y: target.offsetTop
      }
    }

    return {
      locationPic,
      locator,
      startHandler,
      moveHandler,
      endHandler
    }
  }
}

/**
 * 线性插值方法，用作动画补间
 * @param {*} start 开始值
 * @param {*} end 结束值
 * @param {*} factor 插值系数
 */
function lerp(start, end, factor) {
  return (1 - factor) * start + factor * end
}
</script>
<style scoped lang="scss">
.locator {
  z-index: 30;
  top: 266px;
  right: 16px;
  width: 88px;
  height: 28px;
  line-height: 28px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(32, 40, 74, 0.2);
  border-radius: 14px;
  font-size: 14px;
  color: #4c526d;
  letter-spacing: 0.17px;
  text-align: center;
}
</style>
