<template>
  <NavBar :is-return="false" />
  <div class="container" ref="container">
    <Search />
    <Scroller />
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import Search from './components/search.vue'
import Scroller from './components/scroller.vue'
import { useStore } from 'vuex'

export default {
  name: 'Orientation',
  components: { Search, Scroller },
  setup() {
    const store = useStore()
    const container = ref()

    onMounted(() => {
      const screenHeight = store.state.screenHeight
      const offsetTop = container.value.offsetTop
      const transOffset = screenHeight / 2 - offsetTop - 80
      document.documentElement.style.setProperty('--trans--percent', -transOffset + 'px')
    })

    return {
      container
    }
  }
}
</script>
<style lang="scss" scoped>
:root {
  --trans--percent: 40%;
}
</style>
