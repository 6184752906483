<template>
  <div id="container" ref="container" style="height: 100%; width: 100%"></div>
</template>
<script>
import { ref, watch, onBeforeUnmount } from 'vue'
import { Toast } from 'vant'

import carIcon from '@/assets/Orientation/car01.png'
import startIcon from '@/assets/img/startMarker.png'
import endIcon from '@/assets/img/endMarker.png'
import closeIcon from '@/assets/delete.png'

import { toBDMapPoint, toBDMapPointArr } from '@/utils/mapUtil'

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const container = ref(null)
    let Vmap = null //实时高德地图实例
    let moveInfoMes = null //高德地图展示的内容

    // 绑定 ref 后自动生成 map 示例
    watch(container, () => {
      Vmap = new BMapGL.Map('container')
      emit('update:modelValue', Vmap)
      Vmap.enableScrollWheelZoom(true)
      Vmap.addControl(new BMapGL.ZoomControl())
      Vmap.addControl(new BMapGL.ScaleControl())
      const point = new BMapGL.Point(116.404, 39.915)
      // 创建点坐标
      Vmap.centerAndZoom(point, 15)
      Vmap.setDisplayOptions({
        poiIcon: false,
        building: false //是否显示3D建筑物
      })
    })

    onBeforeUnmount(() => {
      Vmap && Vmap.destroy()
      Vmap = null
      moveInfoMes = null
    })

    // 保存车辆信息，重复点击试试定位地图进行所发
    let carPoint = null

    // 绘制实时定位地图
    const getCurrentPos = data => {
      // 重复点击没必要重绘
      if (moveInfoMes) {
        Vmap.centerAndZoom(carPoint, 12)
        return
      }
      const { x, y, deviceId, weight, carUploadDate } = data
      if (!x || !y) {
        Toast('未查询到车辆位置信息')
        return
      }
      // 清空地图
      Vmap.clearOverlays()
      const result = { x, y, deviceId, weight, carUploadDate }
      // 转换坐标
      toBDMapPoint(result)
      const carPos = new BMapGL.Point(result.lng, result.lat)
      // 保存位置信息，重复点击后缩放地图至对应位置
      carPoint = carPos
      Vmap.centerAndZoom(carPos, 12)
      const content = `<div id="tips" style="background-color: #fff;color: #4c526d;width: 172px;padding: 8px;border-radius: 8px;position: relative;font-size: 12px;letter-spacing: 0.14px;">
            <p style="padding: 4px 0;margin:0;">设备ID:${result.deviceId ? result.deviceId : '--'}</p>
            <p style="padding: 4px 0;margin:0;">重量:${result.weight ? result.weight : '--'}</p>
            <p style="padding: 4px 0;margin:0;">时间:${result.carUploadDate ? result.carUploadDate : '--'}</p>
            <div style="width: 12px;height: 12px;position: absolute;background-color: #fff;bottom: -4px;border-radius: 0 0 6px 6px;left: 70px;"></div>
            </div>
            <img src="${carIcon}" style="margin-left: 60px;margin-top: 4px;">
            `
      moveInfoMes = new BMapGLLib.InfoBox(Vmap, content, {
        enableAutoPan: true,
        closeIconMargin: '2px 2px 0 0;z-index: 1;width: 14px;display:none',
        closeIconUrl: closeIcon,
        align: INFOBOX_AT_TOP
      })
      moveInfoMes.open(carPos)
    }

    // 地图画线
    function drawLine(data) {
      // 清空位置标记
      if (moveInfoMes) {
        moveInfoMes.close()
        moveInfoMes = null
      }
      Vmap.clearOverlays()
      // 转换百度坐标系
      const path = toBDMapPointArr(data)
      const pStart = drawPoint(path[0], startIcon)
      const pEnd = drawPoint(path[path.length - 1], endIcon)
      Vmap.addOverlay(pStart)
      Vmap.addOverlay(pEnd)
      let polyList
      if (path[0].hz) {
        polyList = path
      } else {
        polyList = path.map(el => new BMapGL.Point(el.lng, el.lat))
      }
      // 路径转化为点
      const polyline = new BMapGL.Polyline(polyList, {
        strokeColor: '#4C84F1',
        strokeWeight: 5,
        strokeOpacity: 1,
        fillOpacity: 0.5
      })
      Vmap.addOverlay(polyline)
      setTimeout(() => {
        Vmap.setViewport(polyList)
      }, 100)
      return polyline
    }

    // 绘制地图标点 icon
    function drawPoint(position, icon) {
      const posIcon = new BMapGL.Marker(new BMapGL.Point(position.lng, position.lat), {
        offset: new BMapGL.Size(0, -10),
        icon: new BMapGL.Icon(icon, new BMapGL.Size(20, 26), {
          imageSize: new BMapGL.Size(20, 26)
        })
      })
      posIcon.setZIndex(10)
      return posIcon
    }

    return {
      container,
      getCurrentPos,
      drawLine
    }
  }
}
</script>
