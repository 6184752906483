<template>
  <div class="result position-absolute scroll-y" v-if="isSearch && !isEmpty">
    <!-- 公司列表 -->
    <div v-if="companies.length !== 0">
      <div class="company line flex-center" v-for="el in companies" :key="el.id" @click="$emit('view-company', el)">
        <div class="company__text flex-main">
          <div>{{ el.name }}</div>
          <div class="car-number flex">
            <div>
              车辆数：
              <span style="color: #1989fa">{{ el.onLineCount + el.offLineCount }}</span>
            </div>
            <div>
              在线车辆数：
              <span style="color: #2ebf7e">{{ el.onLineCount }}</span>
            </div>
          </div>
        </div>
        <p class="company__arrow"><van-icon name="arrow" /></p>
      </div>
    </div>

    <!-- 公司列表end -->
    <!-- 车辆列表 -->
    <div class="vehicle line flex-center" v-for="(item, index) in cars" :key="index" @click="$emit('view-car', item)">
      <div class="vehicle__status">
        <img :src="item.imgSrc" style="margin: 0; height: 50px" />
      </div>
      <div class="vehicle__number flex-main">{{ item.carNumber }}</div>
      <div class="vehicle__arrow">
        <van-icon name="arrow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companies: { type: Array, required: true },
    cars: { type: Array, required: true },
    isSearch: { type: Boolean },
    isEmpty: { type: Boolean }
  },
  emits: ['view-company', 'view-car']
}
</script>
<style lang="scss" scoped>
.result {
  z-index: 1;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4c4c4c6e;
  font-size: 14px;
  .company {
    height: 42px;
    padding: 8px 12px;
    background-color: #fff;
    &__text {
      font-weight: 500;
      > div {
        margin-bottom: 6px;
      }
      .car-number {
        color: #333;
      }
    }
    &__arrow {
      font-size: 16px;
      text-align: center;
      color: #c8c9cc;
    }
  }
  .vehicle {
    height: 42px;
    padding: 8px 12px;
    background-color: #fff;
    padding-bottom: 20px;
    &__status {
      font-size: 32px;
    }
    &__number {
      padding-left: 10px;
    }
    &__arrow {
      font-size: 16px;
      text-align: center;
      color: #c8c9cc;
    }
  }
  .line::after {
    width: calc(100% - 20px);
  }
}
</style>
