<template>
  <NavBar :is-search="isSearch" />
  <div class="container column position-relative">
    <Header v-model="searchItem" :isSearch="isSearch" @focus="searchFocus" @cancel="isSearch = false" />

    <Scroller
      ref="scrollRef"
      :isEmpty="isEmpty"
      :companies="companies"
      :cars="carInfo"
      @view-company="companyShowDetails"
      @view-car="getCarInfo"
    />
    <!-- 搜索从获取的列表数据中进行筛选 -->
    <SearchResult
      :isSearch="isSearch"
      :isEmpty="isEmpty"
      :companies="resultCompanies"
      :cars="resultCarInfo"
      @view-company="companyShowDetails"
      @view-car="getCarInfo"
    />
  </div>
</template>
<script>
import { reactive, toRefs, watchEffect, computed, ref } from 'vue'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useStore } from 'vuex'
import http from '@/utils/request'

import Header from './components/header.vue'
import Scroller from './components/scroller.vue'
import SearchResult from './components/search-result.vue'
import onlineSrc from '@/assets/Orientation/onlineCar.png'
import offlineSrc from '@/assets/Orientation/offlineCar.png'
export default {
  name: 'CarList',
  components: { Header, Scroller, SearchResult },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      searchItem: '',
      companies: [],
      carInfo: [],
      isEmpty: false,
      isSearch: false
    })
    const scrollRef = ref()

    // 搜索时候需要修改布局
    const searchFocus = () => {
      state.isSearch = true
      scrollRef.value.scrollTop = 0
    }
    // 搜索结果
    const resultCompanies = computed(() => {
      if (state.isEmpty) return []
      return state.companies.filter(
        el => el.name.indexOf(state.searchItem.toUpperCase()) >= 0 && state.searchItem !== ''
      )
    })
    const resultCarInfo = computed(() => {
      if (state.isEmpty) return []
      return state.carInfo.filter(
        el => el.carNumber.indexOf(state.searchItem.toUpperCase()) >= 0 && state.searchItem !== ''
      )
    })
    // 更新车辆数据
    function getCarInfo(carInfo) {
      store.commit('updateCarInfo', { carNumber: carInfo.carNumber, deviceId: carInfo.deviceId })
      router.push({
        name: 'CarDetail',
        query: {
          title: carInfo.carNumber,
          deviceId: carInfo.deviceId
        }
      })
    }
    watchEffect(() => route.params.id, initData(route.params.id))

    // 点击子公司后还会进入这个组件，所以要在 route update 时候更新页面标题
    onBeforeRouteUpdate(async (to, from) => {
      to.meta.title = to.query.companyName
      if (to.params.id !== from.params.id) {
        try {
          initData(to.params.id)
        } catch (error) {
          console.warn(error)
        }
      }
    })
    // 重置修改车牌信息
    store.commit('changeNum/resetState')

    function companyShowDetails(item) {
      router.push({
        name: 'CarList',
        params: { id: item.id },
        query: {
          ...route.query,
          companyName: item.name
        }
      })
    }
    // 查询当前公司下面的车辆以及子公司列表
    async function initData(id) {
      let companyData = await http.post('company/queryCompanyInfoListV3.html', { companyId: id })
      companyData = companyData.data
      let carData = await http.post('company/queryCarInfoListV2.html', { companyId: id })
      carData = carData.data
      carData.forEach(car => {
        // 离线车辆会多一个 offline
        if (car.icon.indexOf('offline') >= 0) {
          car.imgSrc = offlineSrc
          car.status = '离线'
        } else {
          car.imgSrc = onlineSrc
          car.status = '在线'
        }
      })
      // 在线车辆放前面
      carData.sort((a, b) => {
        return a.icon.length - b.icon.length
      })
      if (companyData.length === 0 && carData.length === 0) {
        state.isEmpty = true
        return
      }
      state.companies = companyData
      state.carInfo = carData
    }
    return {
      searchFocus,
      scrollRef,
      ...toRefs(state),
      getCarInfo,
      companyShowDetails,
      resultCompanies,
      resultCarInfo
    }
  }
}
</script>
