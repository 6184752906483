<template>
  <div class="flex-main" style="overflow-y: scroll">
    <van-empty image="search" description="未找到相关信息" v-if="isEmpty" />
    <div class="wrapper flex-main" v-else>
      <!-- 公司列表 -->
      <div v-if="companies.length !== 0">
        <div class="company line flex-center" v-for="el in companies" :key="el.id" @click="$emit('view-company', el)">
          <div class="company__text flex-main">
            <div>{{ el.name }}</div>
            <div class="car-number flex">
              <div>
                车辆数:
                <span style="color: #1989fa">{{ el.onLineCount + el.offLineCount }}</span>
              </div>
              <div>
                &nbsp;&nbsp;在线车辆数:
                <span style="color: #2ebf7e">{{ el.onLineCount }}</span>
              </div>
            </div>
          </div>
          <div class="company__arrow"><van-icon name="arrow" /></div>
        </div>
      </div>

      <!-- 公司列表end -->
      <!-- 车辆列表 -->
      <div class="vehicle line flex-center" v-for="(item, index) in cars" :key="index" @click="$emit('view-car', item)">
        <div class="vehicle__status">
          <van-image width="42" height="42" :src="item.imgSrc" />
        </div>
        <div class="vehicle__number flex-main">{{ item.carNumber }}</div>
        <div class="vehicle__arrow">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companies: { type: Array, required: true },
    cars: { type: Array, required: true },
    isEmpty: { type: Boolean }
  },
  emits: ['view-company', 'view-car']
}
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 8px 0;
  background-color: #fff;
  flex-grow: 1;
  font-size: 14px;

  .company {
    height: 42px;
    padding: 8px 12px;
    &__text {
      font-weight: 500;
      > div {
        margin-bottom: 6px;
      }
      .car-number {
        color: #333;
      }
    }
    &__arrow {
      font-size: 16px;
      text-align: center;
      color: #c8c9cc;
    }
  }
  .vehicle {
    height: 42px;
    padding: 8px 12px;
    &__status {
      font-size: 32px;
    }
    &__number {
      padding-left: 10px;
    }
    &__arrow {
      font-size: 16px;
      text-align: center;
      color: #c8c9cc;
    }
  }
  .line::after {
    width: calc(100% - 20px);
  }
}
</style>
