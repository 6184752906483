<template>
  <div class="wrapper position-fixed">
    <div class="content">
      <div class="time flex-spa line">
        <div class="status align-center">
          <span class="status-dot" style="background-color: #1adcbb"></span>
          <span class="status-title">开始时间</span>
        </div>
        <div class="align-center">
          <input type="text" readonly v-model="showStart" @click="selectTime(start, true)" />
        </div>
        <div class="align-center">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="time flex-spa line">
        <div class="status align-center">
          <span class="status-dot" style="background-color: #ff152d"></span>
          <span class="status-title">结束时间</span>
        </div>
        <div class="align-center">
          <input type="text" readonly v-model="showEnd" @click="selectTime(end, false)" />
        </div>
        <div class="align-center">
          <van-icon name="arrow" />
        </div>
      </div>
      <div style="text-align: center">
        <van-button class="action" round type="primary" @click="$emit('search')">查询轨迹</van-button>
      </div>
    </div>
  </div>
  <van-popup
    :show="datePickerShow"
    position="bottom"
    round
    style="height: '46%'"
    @click-overlay="datePickerShow = false"
  >
    <van-datetime-picker
      v-model="currentDate"
      type="datetime"
      title="选择时间"
      @confirm="confirmDate"
      @cancel="datePickerShow = false"
    />
  </van-popup>
</template>
<script>
import { ref, computed } from 'vue'

import { formatTimeStr } from '@/utils/common'

export default {
  props: {
    startTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    }
  },
  emits: ['update:startTime', 'update:endTime', 'search'],
  setup(props, { emit }) {
    const start = computed({
      get() {
        return props.startTime
      },
      set(val) {
        emit('update:startTime', val)
      }
    })

    const end = computed({
      get() {
        return props.endTime
      },
      set(val) {
        emit('update:endTime', val)
      }
    })
    // 只能选择到分，展示也只展示到分
    const showStart = computed(() => start.value.slice(0, 16))

    const showEnd = computed(() => end.value.slice(0, 16))

    const datePickerShow = ref(false)

    const currentDate = ref(new Date())

    // 是否选择开始时间
    let strFlag = ref(true)

    const selectTime = (time, flag) => {
      datePickerShow.value = true
      strFlag.value = flag
      currentDate.value = showSelect(time)
    }

    // 确认时间操作
    const confirmDate = time => {
      datePickerShow.value = false
      const result = formatTimeStr(time)
      if (strFlag.value) {
        start.value = result
      } else {
        end.value = result
      }
    }

    //点击选择框显示时间选择框
    function showSelect(time) {
      // safari 支持的日期格式不同，转换为可以使用的 Date 参数
      let params = time.replace(/[-:]/g, ' ')
      params = params.split(' ')
      params[1] = params[1] - 1
      const currentDate = new Date(...params)
      return currentDate
    }

    return {
      strFlag,
      start,
      end,
      showStart,
      showEnd,
      datePickerShow,
      currentDate,
      selectTime,
      confirmDate
    }
  }
}
</script>
<style scoped lang="scss">
.wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 154px;
  top: 104px;
  z-index: 20;

  .content {
    margin: 0 16px 0;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(32, 40, 74, 0.05);
    border-radius: 8px;
    padding-bottom: 16px;
  }
  .time {
    height: 48px;
    margin-left: 10px;
    margin-right: 14px;
    .status {
      min-width: 10px;
      &-dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      &-title {
        margin-left: 6px;
      }
    }
    input {
      border: none;
      outline: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #20284a;
      letter-spacing: 0;
    }
  }
  .action {
    box-sizing: border-box;
    width: 200px;
    height: 36px;
    background-image: linear-gradient(131deg, #35a3ff 0%, #1c74f2 100%);
    border-radius: 25px;
    display: inline-block;
    margin-top: 12px;
  }
}
</style>
