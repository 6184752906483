<template>
  <div class="scroller position-relative" ref="scrollEl">
    <van-config-provider :theme-vars="themeVars">
      <van-index-bar :index-list="indexList">
        <div v-for="item in companiesData" :key="item.index" class="company">
          <van-index-anchor :index="item.index" />
          <div class="info" v-for="el in item.companies" :key="el.id" @click="companyShowDetails(el)">
            <div class="info__text">
              {{ el.name }}
            </div>
            <div class="info__number">
              车辆数:&nbsp;
              <span style="color: #1989fa">
                {{ el.count }}
              </span>
            </div>
          </div>
        </div>
      </van-index-bar>
    </van-config-provider>
  </div>
</template>
<script>
import { ref, onMounted, onActivated } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import http from '@/utils/request'

const pinyin = require('pinyin')
export default {
  name: 'Scroller',
  setup() {
    const themeVars = {
      indexAnchorStickyTextColor: '#1c74f2',
      indexAnchorPadding: '0'
    }
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const companyList = ref([])
    const companiesData = ref([
      {
        index: '',
        companies: []
      }
    ])
    const indexList = ref([])

    onMounted(() => {
      initData()
    })

    onActivated(() => {
      // 每次 actived 清空修改车辆中的数据
      // 防止进入车辆信息，修改车牌，信息被带到新增车辆中
      store.commit('changeNum/resetState')
      scrollEl.value.scrollTop = store.state.oriScroll
    })
    function companyShowDetails(item) {
      router.push({
        name: 'CarList',
        params: { id: item.id },
        query: {
          ...route.query,
          companyName: item.name
        }
      })
    }

    // 记录滚动位置
    const scrollEl = ref(null)
    onBeforeRouteLeave(() => {
      const position = scrollEl.value.scrollTop
      store.commit('updateOriScroll', position)
    })

    async function initData() {
      try {
        let { data } = await http.post('company/queryCompanyInfoListV2.html')
        let indexStr = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase()
        let indexData = []
        let companiesList = []
        for (let char of indexStr) {
          indexData.push({
            index: char,
            companies: []
          })
        }
        data.forEach(el => {
          companiesList.push(el)
          let firstChar = pinyin(el.name.trim(), {
            style: pinyin.STYLE_FIRST_LETTER
          })[0][0].toUpperCase()
          // 英文单词会全部选中
          const index = indexStr.indexOf(firstChar[0])
          indexData[index].companies.push(el)
        })
        indexData = indexData.filter(info => info.companies.length !== 0)
        let indexArr = indexData.map(el => el.index)
        companiesData.value = indexData
        companyList.value = companiesList
        indexList.value = indexArr
      } catch (error) {
        console.warn(error)
      }
    }

    return {
      themeVars,
      scrollEl,
      companiesData,
      companyShowDetails,
      indexList
    }
  }
}
</script>
<style scoped lang="scss">
.scroller {
  max-height: calc(100% - 50px);
  overflow-y: scroll;
  box-sizing: border-box;
  padding-bottom: 20px;
  .company {
    margin: 0 58px 12px 22px;
    position: relative;
    &::after {
      position: absolute;
      bottom: -8px;
      content: '';
      height: 1px;
      width: 100%;
      background-color: #f1f1f1;
    }
    &:last-child::after {
      height: 0;
    }
  }

  :deep .van-index-bar__index {
    padding: 0;
    margin-right: 15px;
    box-sizing: border-box;
    width: 36px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #777c90;
    letter-spacing: 0;
    text-align: center;
  }
  :deep .van-index-bar__index--active {
    height: 30px;
    line-height: 30px;
    background: #1c74f2;
    font-size: 20px;
    color: #ffffff;
  }
  :deep .van-index-bar__sidebar {
    transform: translateY(var(--trans--percent));
  }
  .info {
    position: relative;
    padding: 8px 0;
    &__text {
      font-size: 14px;
      height: 20px;
      color: #4c526d;
    }
    &__number {
      color: #777c90;
      margin-top: 4px;
    }
  }
}
</style>
