<template>
  <div class="search position-relative">
    <van-search placeholder="搜索公司或车辆信息" shape="round" @focus="searchCompanyCar">
      <template #left-icon>
        <div class="align-center">
          <van-image width="16" height="16" :src="searchIcon" />
        </div>
      </template>
    </van-search>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'

import searchIcon from '@/assets/componentIcon/search.png'
export default {
  name: 'Search',
  setup() {
    const router = useRouter()
    const route = useRoute()

    const searchCompanyCar = () => {
      router.push({
        name: 'searchLayout',
        query: {
          ...route.query
        }
      })
    }

    return {
      searchIcon,
      searchCompanyCar
    }
  }
}
</script>
<style scoped lang="scss">
.search {
  height: 50px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(32, 40, 74, 0.05);
  z-index: 100;
}
</style>
