<template>
  <van-search
    class="search position-relative"
    v-model="searchKey"
    show-action
    shape="round"
    placeholder="请输入4位搜索关键词"
    @focus="$emit('focus')"
    @cancel="$emit('cancel')"
  >
    <template #left-icon>
      <div class="align-center">
        <van-image width="16" height="16" :src="searchIcon" />
      </div>
    </template>
  </van-search>
</template>
<script>
import { computed } from 'vue'

import searchIcon from '@/assets/componentIcon/search.png'

export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    isSearch: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue', 'focus', 'cancel'],
  setup(props, { emit }) {
    const searchKey = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    return {
      searchIcon,
      searchKey
    }
  }
}
</script>
<style scoped lang="scss">
.search {
  height: 50px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(32, 40, 74, 0.05);
  z-index: 100;
}
</style>
